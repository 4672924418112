import { useEffect, useState } from 'react'
//import { useEmployeesContext } from "../hooks/useEmployeesContext"
import EmployeeDetails from '../components/employeeDetails'
import EmployeeForm from '../components/EmployeeForm'

export const Home = () => {
   const [employees, setEmployees]= useState(null) 
  //const {employees, dispatch } = useEmployeesContext()

    useEffect(() => {
        const fetchEmployees = async () => {
          const response = await fetch('/api/employees')
          const json = await response.json()

          if (response.ok) {
           // dispatch({type: 'SET_WORKOUTS', payload: json})
            setEmployees(json)
          }
        }

        fetchEmployees()
    }, []) 
    
    return (
    <div className="home">
      <div className="employees">
       <table> 
      <tr><strong>Employee Name</strong></tr><tr><strong>Employee ID </strong></tr><tr><strong>Address</strong></tr>
      
      {employees && employees.map((employee) => (
        <EmployeeDetails key={employee._id} employee={employee}/>
      ))}
      </table>
      </div>
      <EmployeeForm />
    </div>
  )
}


