import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./styles.css";
import {Home} from './pages/Home';
import {NoMatch } from './NoMatch';
import {Container} from 'react-bootstrap';
import MenuAppBar from './MenuAppBar';
import EmployeeForm from './components/EmployeeForm';

function App() {
  return (
    <div><>
    <MenuAppBar />
    
    <Container>  
     <Router>
       <Switch>
       <Route exact path="/" component={Home} />
       <Route path="/EmployeeForm" component={EmployeeForm} />
       <Route component={NoMatch} />
      </Switch>
     </Router>
     </Container>
     </>

    </div>
    
  );
}

export default App;
