import React, { useState } from 'react'

const EmployeeForm = () => {
 
  const [EmployeeName, setTitle] = useState('')
  const [EmployeeID, setLoad] = useState('')
  const [Address, setReps] = useState('')
  const [error, setError] = useState(null)
  
  const handleSubmit = async (e) => {
    e.preventDefault()

    const employee = {EmployeeName, EmployeeID, Address}

    const response = await fetch('https://empdb.onrender.com/api/employees', {
        method: 'POST',
        body: JSON.stringify(employee),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const json = await response.json()
    if (!response.ok){
      setError(json.error)  
    }
    if (response.ok) {
        setTitle('')
        setLoad('')
        setReps('')
        setError(null)
        console.log('new employee added', json)
   //     dispatch({type: 'CREATE_WORKOUT', payload: json})
    }
  }
  return (
    <form className="create" onSubmit={handleSubmit}>
        <h3>Add a New Employee</h3>
        <label>Name of Employee:</label>
        <input
         type="text"
         onChange={(e) => setTitle(e.target.value)}
         value={EmployeeName}
         />

          <label> Employee ID :</label>
          <input
            type="number"
            onChange={(e) => setLoad(e.target.value)}
            value={EmployeeID}
            />

            <label>Address</label>
            <input
              type="string"
              onChange={(e) => setReps(e.target.value)}
              value = {Address}
            />

            <button> Add Employee</button>
            {error && <div className="error">{error}</div>}
          </form>
  )
}

export default EmployeeForm
