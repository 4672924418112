import React from 'react'

const EmployeeDetails = ({ employee }) => {
/*  const handleClick = async () => {
    const response = await fetch('/apis/employees/' + employee._id, {
      method: 'DELETE'
    })
    const json = await response.json()

    if (response.ok) {

    }

  } */
  return (
    <div className="employee-details">
      <tr>
      <td>{employee.EmployeeName}</td><td>{employee.EmployeeID}</td><td>{employee.Address}</td><td>{employee.createdAt}</td>
      </tr>
      
    </div>
  )
}

export default EmployeeDetails
